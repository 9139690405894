<template>
  <div id="app">
    <router-view></router-view>
    
     <footer class="text-center text-white footer" style="background-color: #f1f1f1;padding: 7em 0;">
       
       <div class="row justify-content-center">
					<div class="col-md-12 text-center">
						<p class="menu">
							<a href="/">Home</a>
              <router-link :to="'/contact'">Contatti</router-link>
							<router-link :to="'/about'">Chi siamo</router-link>
						</p>
					</div>
				</div>

        <!-- Grid container -->
        <div class="container pt-4">
          <!-- Section: Social media -->
          <section class="mb-4 footer_menu">

            <!-- Whatsapp -->
            <a
              class="btn btn-link btn-floating btn-lg text-dark m-1"
              href="https://wa.me/393899513886"
              target="_blank"
              role="button"
              data-mdb-ripple-color="dark"
              ><i class="fab fa-whatsapp"></i></a>

            <!-- Facebook -->
            <a
              class="btn btn-link btn-floating btn-lg text-dark m-1"
              href="#!"
              role="button"
              data-mdb-ripple-color="dark"
              ><i class="fab fa-facebook-f"></i></a>
      
            <!-- Google -->
            <a
              class="btn btn-link btn-floating btn-lg text-dark m-1"
              href="https://www.google.com/search?q=global+ferry+brindisi"
              target="_blank"
              role="button"
              data-mdb-ripple-color="dark"
              ><i class="fab fa-google"></i
            ></a>
      
            <!-- Instagram -->
            <a
              class="btn btn-link btn-floating btn-lg text-dark m-1"
              href="#!"
              role="button"
              data-mdb-ripple-color="dark"
              ><i class="fab fa-instagram"></i
            ></a>
      
          </section>
          <!-- Section: Social media -->
        </div>

        

        <!-- Grid container -->
      
        <!-- Copyright -->
        <div class="text-center text-dark p-3">
          © 2020 Copyright ©2022 All rights reserved |  
          <a class="text-dark" href="https://globalferry.eu">GlobalFerry.eu</a>
        </div>
        <!-- Copyright -->
      </footer>

  </div>
</template>

<script>

window.onpopstate = function () {
    location.reload()
};

export default {
  data() {
    return {
    }
  },
}
</script>
<style lang="scss">
#nav {
  text-align: center;
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
.footer{
    bottom: 0;
    width: 100%;
    background: #f1f6f5;
}
.menu > a{
  text-decoration: none !important;
  margin:15px !important;
  text-transform:uppercase ;
  color: #7a7171;
  font-size: 15px;
  font-weight: 800;
}

.footer_menu > a{
    width: 50px;
    border: 2px solid transparent;
    border-radius: 50%;
    border-color: #30c1af !important
}

.fab{
  color:#30c1af !important;
}

</style>
