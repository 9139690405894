<template>
        <div class="container base_container">
          <div class="itinerari">
                <div class="intestazione">
                <span id="titolo">{{titolo}}</span>
                </div>
                <div class="table-responsive">
                <table class="table">
                  <thead style="background-color: #0d77d3;color:white;">
                    <tr>
                      <th scope="col"></th>
                      <th scope="col"></th>
                      <th scope="col">Itinerario</th>
                      <th scope="col">Data partenza</th>
                      <th scope="col">Data arrivo</th>
                      <th scope="col">Ora partenza</th>
                      <th scope="col">Ora arrivo</th>
                      <th scope="col">Durata</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="tratta in data" :key="tratta.id_tratta">
                      <td><input type="radio"  v-model="id_tratta_sel" :value="tratta.id_tratta" @change="change"></td>
                      <th scope="row"><img src="ferry.ico"></th>
                      <td>{{tratta.Nome}}</td>
                      <td>{{calcola_date(tratta.data_partenza)}}</td>
                      <td>{{calcola_date(tratta.data_arrivo)}}</td>
                      <td>{{calcola_ora(tratta.ora_p)}}</td>
                      <td>{{calcola_ora(tratta.ora_a)}}</td>
                      <td>{{cacolo_tempo(tratta.data_partenza,tratta.data_arrivo,tratta.ora_p,tratta.ora_a)}}</td>
                    </tr>
                  </tbody>
                </table>
                </div>
          </div>
        </div>

</template>

<script>
export default {
    name: 'ElencoRisultati',
    data() {
        return {
            id_tratta_sel:''
        }
    },
    props:{
        'titolo':String,
        data:{
            type:Array
        }
    },
    methods:{
        change()
        {
            this.$emit('return',this.id_tratta_sel)
        },
        calcola_ora(ora){
          return ora.slice(0,ora.length-3);
        },
        calcola_date(data)
        {
          let data_2=new Date(data);
          return data_2.toLocaleDateString('it-IT');
        },
        cacolo_tempo(data_partenza,data_arrivo,ora_p,ora_a)
        {

              //create date format          
              var timeStart = new Date(data_partenza +" "+ora_p);
              var timeEnd = new Date(data_arrivo +" "+ ora_a);
              var ret="";
              var hourDiff = timeEnd-timeStart;

              var hh = Math.floor(hourDiff / 1000 / 60 / 60);
              hourDiff -= hh * 1000 * 60 * 60;
              var mm = Math.floor(hourDiff / 1000 / 60);
              hourDiff -= mm * 1000 * 60;
              var ss = Math.floor(hourDiff / 1000);
              hourDiff -= ss * 1000;

              if(hh>0)
              {
                if(hh>1 && hh<2)
                {
                  ret=ret+" "+hh+" ora";
                }else{
                  ret=ret+" "+hh+" ore";
                }
        
              }

              if(mm>0)
              {
                if(mm>=1&&mm<2)
                {
                  if(hh>0)
                  {
                    ret=ret+" e "+mm+" minuto";
                  }
                  else{
                    ret=ret+" "+mm+" minuto";
                  }
                  
                }else{
                  if(hh>0)
                  {
                    ret=ret+" e "+mm+" minuti";
                  }
                  else{
                    ret=ret+" "+mm+" minuti";
                  }
                  
                }

              }

              return ret;
        }
    }
}
</script>
<style>

.base_container{
    padding-right: 5%;
    padding-left: 5%;
}

@media only screen and (max-width: 1024px) {
  .base_container{
    padding-top: 6%;
  }
}

@media (max-width: 767px)
{
  .base_container{
    padding-left: 5px !important;
    padding-right: 5px !important;
  }

}
</style>

<style scoped>

.itinerari{
  border: 1px solid lightgrey;
  border-radius: 4px;
}
.intestazione
{
  font-size: 19px;
  color: black;
  background-color: #f1f6f5;
  border-color: #ddd;
  padding: 10px 15px
}
th{
  font-weight: 300;
  font-size: 14px !important;
}
.dettagli{
  margin-left:5px;
}
tbody{
    color: #333;
  background-color: #f5f5f5;
}
#titolo{
    margin-top:5px;
    margin-left:10px;   
    font-weight:500 ;
}
th{
    font-size: 12px;
}

</style>