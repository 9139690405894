<template>

<div>
    <!--
    <select class="form-control form-select" @change="onChange($event)" placeholder="Scegli la tua destinazione" v-model="selected">
        <option value="0" selected="selected">Seleziona la tua tratta</option>
        <option v-for="tratta in this.data" :value="tratta.id_porto_p+'.'+tratta.id_porto_a"   :key="tratta.id_porto_p+'.'+tratta.id_porto_a">
            {{tratta.Nome}}
        </option>
    </select>
    -->
    <input list="suggestionList" class="form-control" placeholder="Seleziona tratta" v-on:input="selezionato" id="answerInput">
    <datalist id="suggestionList">
        <option v-for="tratta in this.data" :data-value="tratta.id_porto_p+'.'+tratta.id_porto_a"  :key="tratta.id_porto_p+'.'+tratta.id_porto_a">
            {{tratta.Nome}}
        </option>
    </datalist>
    <input type="hidden" name="answer" id="answerInput-hidden" :value="id_porto">
</div>

</template>

<script>



export default {
    name:'elenco_tratte',
    props:{
        'value':String,
        'data':Array
    },
    data() {
        return{
            tratte:[],
            id_porto:''
        }
    },
    methods:{
        selezionato(v)
        {
            var nome_tratta=v.target.value;

            var options=document.querySelectorAll('#suggestionList option');

            for(var i = 0; i < options.length; i++) {
                var option = options[i];

                if(option.innerText.trim() === nome_tratta.trim()) {
                    //hiddenInput.value = option.getAttribute('data-value');
                    this.id_porto=option.getAttribute('data-value');
                    break;
                }
                else{
                    this.id_porto='';
                }
            }

            this.$emit('input', this.id_porto);

        }
    },
    computed:{
        selected:{
            get(){return this.value},
            set(v) {
                this.$emit('input', v);
            }
        }
    }
}
</script>