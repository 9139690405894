<template>
<div class="header-nightsky" style="background:none;box-shadow:inset 0 0 0 1000px #2c4864"> 

    
    <nav class="navbar navbar-expand-lg   static-top">
        <div class="container">    
            <a class="navbar-brand" href="/">
                <img src="logo.png" width="300px">
            </a>
            <button class="navbar-toggler custom-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>

            <div class="collapse navbar-collapse" id="navbarSupportedContent">
              <ul class="navbar-nav ms-auto">
                <li class="nav-item">  
                  <a href="/">Home</a>
                </li>
                <li class="nav-item">
                    <router-link :to="'/contact'">Contatti</router-link>
                </li>
                <li class="nav-item">
                  <router-link :to="'/about'">Chi siamo</router-link>
                </li>
              </ul>
            </div>
        </div>
      </nav>
	<div>
		<horizontal-stepper :steps="demoSteps" @completed-step="completeStep" :top-buttons="true"
                                            @active-step="isStepActive"></horizontal-stepper>
	</div>
</div>
</template>

<script>

	import HorizontalStepper from 'vue-stepper/src/HorizontalStepper.vue';

    import StepOne from '@/components/ScegliTratte.vue';
    import StepTwo from '@/components/ScegliSistemazioni.vue';
    import StepTre from '@/components/ScegliServizi.vue';
    import StepFor from '@/components/RichiestaPrenotazione.vue'

    export default {
        name: 'app',
        components: {
            HorizontalStepper
        },
        data(){
            return {
                demoSteps: [
                    {
                        icon: 'mail',
                        name: 'first',
                        title: 'Itinerari',
                        subtitle: '',
                        component: StepOne,
						data:[],
                        completed: false

                    },
                    {
                        icon: 'report_problem',
                        name: 'second',
                        title: 'Sistemazioni',
                        subtitle: '',
						data:[],
                        risultati_andata:'',
                        risultati_ritorno:'',
                        component: StepTwo,
                        completed: false
                    },
                    {
                        icon: 'announcement',
                        name: 'third',
                        title: 'Servizi aggiuntivi',
                        subtitle: '',
						data:[],
                        risultati_andata:'',
                        risultati_ritorno:'',
                        component: StepTre,
                        completed: false
                    },
					{
						icon: 'announcement',
                        name: 'For',
                        title: 'Richiesta Prenotazione',
                        subtitle: '',
                        data:[],
                        risultati_andata:'',
                        risultati_ritorno:'',
                        component: StepFor,
                        completed: false
					}
                ],
                activeStep: 0
            }
        },
        computed: {},
        created(){
            if(this.$route.params.form==undefined)
            {
                 this.$router.push({ name: 'Home',});
            }
        },
        mounted()
        {
            this.demoSteps[0].form=this.$route.params.form;
            this.demoSteps[1].form=this.$route.params.form;
            this.demoSteps[2].form=this.$route.params.form;
            this.demoSteps[3].form=this.$route.params.form;
            this.demoSteps[0].risultati_andata=this.$route.params.andata;
            this.demoSteps[0].risultati_ritorno=this.$route.params.ritorno;
            
        },
        methods: {
            completeStep(payload) {
                this.demoSteps.forEach((step) => {
                    if (step.name === payload.name) {
                        step.completed = true;
                    }
                })
            },
            isStepActive(payload) {
                this.demoSteps.forEach((step) => {
                    if (step.name === payload.name) {
                        if(step.completed === true) {
                            step.completed = false;
                        }
                    }
                })
            }
        }
    }
</script>

<style>

.stepper-button{
        background-color: #e1903c !important;
        color: white !important;
}

/* Set the border color */
          
        .custom-toggler.navbar-toggler {
            border-color: #30c1af !important;
        }
        /* Setting the stroke to green using rgb values (0, 128, 0) */
          
        .custom-toggler .navbar-toggler-icon {
            background-image: url(
"data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(48, 193, 175, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
        }

</style>

<style scoped>

</style>