<template>
    <div>
        <div class="container base_container">

            <div class="row">
                <template v-if="this.id_tratta_andata!=''">
                    <div class="col-md-12 viaggi viaggio_andata">
                        <div class="panel-primary">

                            <div class="title">
                                <span class="panel-title">Porto di partenza</span>
                            </div>
                            <ElencoServizi :titolo="'andata'" :id_tratta="this.id_tratta_andata" :form="this.form" v-on:onReturn="serviziandata_return"></ElencoServizi>
                        </div>
                    </div>
                </template>

            </div>

        </div>
    </div>
</template>

<script>

import ElencoServizi from '@/components/ElencoServizi.vue'

export default {
    name:'step1',
    components:{ElencoServizi},
    props: ['clickedNext', 'currentStep','GenericGlobalData','form'],
    data() {
        return {
            valid:true,
            id_tratta_andata:'',
            id_tratta_ritorno:'',
            servizi_andata:''
        }
    },
    watch:{
        servizi_andata:{
            handler()
            {
                this.$emit('onReturn',{'tipo':'servizi_andata','valore':this.servizi_andata});
                this.$emit('can-continue', {value: true});
            },
            deep:true
        }
    },
    created(){
            this.$emit('can-continue', {value: true});
    },
    mounted() {
            
            this.$emit('can-continue', {value: true});

            if(this.valid)
            {
                this.$emit('can-continue', {value: true});
            }

            this.id_tratta_andata=this.GenericGlobalData[0].id_tratta_andata;
            this.id_tratta_ritorno=this.GenericGlobalData[1].id_tratta_ritorno;
        },
    methods:{
        serviziandata_return(elenco_servizi)
        {
            this.servizi_andata=elenco_servizi;
        }
    }
}
</script>

<style scoped>
.panel-primary{
    border:1px solid transparent;
    border-color: #337ab7;
    border-radius: 5px;
}
.viaggi{
    padding: 13px;
    margin-top:5px;
}

@media (max-width: 767px)
{
        .viaggio_andata{
            margin-top:5%;
        }       
}
</style>
