<template>

<div class="header-nightsky">
    <nav class="navbar navbar-expand-lg   static-top">
        <div class="container">    
            <a class="navbar-brand" href="/">
                <img src="logo.png" width="300px">
            </a>
            <button class="navbar-toggler custom-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>

            <div class="collapse navbar-collapse" id="navbarSupportedContent">
              <ul class="navbar-nav ms-auto">
                <li class="nav-item">  
                  <a href="/">Home</a>
                </li>
                <li class="nav-item">
                    <router-link :to="'/contact'">Contatti</router-link>
                </li>
                <li class="nav-item">
                  <router-link :to="'/about'">Chi siamo</router-link>
                </li>
              </ul>
            </div>
        </div>
      </nav>
      <template v-if="errore">
                    <div lass="container">
                      <div class="row">
                        <div class="col-md-3">
                       
                        </div>
                        <div class="col-md-6">
                            <b-alert show variant="danger" style="margin-bottom:-15px">
                                <h4 class="alert-heading">Attenzione</h4>
                                <p>
                                Nessuna tratta disponibile per le date indicate
                                </p>
                                <template v-if="prima_data_p_disp!=''">
                                  <p>La prima data partenza disponibile è {{formatta_date(prima_data_p_disp)}}</p>
                                </template>
                                <template v-if="prima_data_r_disp!=''">
                                  <p>La prima data ritorno disponibile è {{formatta_date(prima_data_r_disp)}}</p>
                                </template>
                                
                                <hr>
                                <p class="mb-0">
                                
                                </p>
                            </b-alert>
                        </div>
                        <div class="col-md-3">
                          
                        </div>
                      </div>
                    </div>
        </template>
      <div class="hero">
         <CustomForm id="form-custom" v-on:form_submit="form_restituito"></CustomForm>
      </div>
  </div>
  
</template>

<script>

import CustomForm from '@/components/c-form.vue'
import ServizioApi from '@/servizi/ServizioApi.js'


export default {
  name: 'Home',
  components:{
    CustomForm
  },
  data() {
    return {
      form:'',
      errore:false,
      tratte_andata:[],
      tratte_ritorno:[],
      id_tratta_andata_sel:'',
      id_tratta_ritorno_sel:'',
      prima_data_p_disp:'',
      prima_data_r_disp:''
    }
  },
  methods:{
    formatta_date(data)
    {
        let data_2=new Date(data);
        return data_2.toLocaleDateString('it-IT');
    },
    async form_restituito(form)
    {

      this.form=form;
       await ServizioApi.getTratte(1,this.form)
       .then(risposta=>(this.tratte_andata=risposta.data.data))
       console.log(this.tratte_andata);

      if(form.andata_ritorno=="1")
      {
        await ServizioApi.getTratte(2,this.form)
       .then(risposta=>(this.tratte_ritorno=risposta.data.data))
        console.log(this.tratte_ritorno);
      }

      if((this.tratte_andata.length>0 && form.andata_ritorno!="1")||(form.andata_ritorno=="1" && this.tratte_andata.length>0 && this.tratte_ritorno.length>0))
      {
        this.$router.push({ name: 'Stepper', params: { form: this.form, andata:this.tratte_andata,ritorno:this.tratte_ritorno}});
      }
      else{
        
        this.prima_data_p_disp='';
        this.prima_data_r_disp='';

        this.errore=true;

        if(form.andata_ritorno!="1")
        {
          await ServizioApi.getDataDisp(0,this.form.id_tratta_andata,this.form.data_partenza)
          .then(risposta=>(this.prima_data_p_disp=risposta.data.data_disponibile))
        }
        else{
          if(this.tratte_andata.length<=0)
          {
            await ServizioApi.getDataDisp(0,this.form.id_tratta_andata,this.form.data_partenza)
            .then(risposta=>(this.prima_data_p_disp=risposta.data.data_disponibile))
          }

          if(this.tratte_ritorno.length<=0)
          {
            await ServizioApi.getDataDisp(1,this.form.id_tratta_andata,this.form.data_ritorno)
            .then(risposta=>(this.prima_data_r_disp=risposta.data.data_disponibile))
          }

        }
        

      }
      
    },
    get_risultato_andata(risultato)
    {
      this.id_tratta_andata_sel=risultato;
    },
    get_risultato_ritorno(risultato)
    {
      this.id_tratta_ritorno_sel=risultato;
    }
  }
}
</script>

<style scoped>

.myform {
            position: absolute;
            height: 60%;
            width: 80%;
            top: 20%;
            left: 10%;
        }
  .custom-toggler.navbar-toggler {
            border-color: white !important;
        }
        /* Setting the stroke to green using rgb values (0, 128, 0) */
          
        .custom-toggler .navbar-toggler-icon {
            background-image: url(
"data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 255)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
        }
</style>

