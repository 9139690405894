<template>
    <div>
        <div class="container base_container">

            <div class="row">

                <template v-if="this.id_tratta_andata!=''">
                    <div :class="[this.id_tratta_ritorno!='' ? 'col-md-6 ' : 'col-md-12','viaggi viaggio_andata']">
                        
                        <div class="panel-primary">
                            <div class="title">
                                <span class="panel-title">Viaggio andata</span>
                            </div>
                            <FormSistemazioni :titolo="'andata'" :id_tratta="this.id_tratta_andata" :form="this.form" v-on:onReturn="form_restituito_andata"></FormSistemazioni>
                        </div>

                    </div>
                </template>

                <template v-if="this.id_tratta_ritorno!=''">
                    <div class="col-md-6 viaggi  viaggio_ritorno">
                        <div class="panel-primary">

                            <div class="title">
                                <span class="panel-title">Viaggio ritorno</span>
                            </div>
                            <FormSistemazioni :titolo="'ritorno'" :id_tratta="this.id_tratta_ritorno" :form="this.form" :form_andata="form_andata" v-on:onReturn="form_restituito_ritorno"></FormSistemazioni>

                        </div>
                        
                    </div>
                </template>
            </div>

        </div>
    </div>
</template>

<script>

import FormSistemazioni from '@/components/FormSistemazioni.vue'

export default {
    name:'step1',
    components:{FormSistemazioni},
    props: ['clickedNext', 'currentStep','GenericGlobalData','form'],
    data() {
        return {
            valid:false,
            id_tratta_andata:'',
            id_tratta_ritorno:'',
            form_andata:'',
            form_ritorno:''
        }
    },
    watch:{
        form_andata:{
            handler()
            {
                if(this.form_andata.persona.eta.length>0)
                {
                    this.$emit('onReturn',{'tipo':'sistemazioni_andata','valore':this.form_andata});
                }
                if(this.form_andata.persona.eta.length>0 && this.form_andata.persona.eta.length-1==parseInt(this.form.numero_passeggeri) && ((this.id_tratta_ritorno!="" && this.form_ritorno.persona.eta.length>0 && this.form_ritorno.persona.eta.length-1== parseInt(this.form.numero_passeggeri)) || this.id_tratta_ritorno==""))
                {
                    this.valid=true;
                    this.$emit('can-continue', {value: true});
                }
                else{
                    this.valid=false;
                    this.$emit('can-continue', {value: false});
                }
            },
            deep:true
        },
        form_ritorno:{
            handler()
            {
                if(this.form_ritorno.persona.eta.length>0)
                {
                    this.$emit('onReturn',{'tipo':'sistemazioni_ritorno','valore':this.form_ritorno});
                }

                if(this.form_ritorno.persona.eta.length>0 && this.form_ritorno.persona.eta.length-1==parseInt(this.form.numero_passeggeri) && ((this.id_tratta_andata!="" && this.form_andata.persona.eta.length>0 && this.form_andata.persona.eta.length-1== parseInt(this.form.numero_passeggeri)) || this.id_tratta_andata==""))
                {
                    this.valid=true;
                    this.$emit('can-continue', {value: true});
                }
                else{
                    this.valid=false;
                    this.$emit('can-continue', {value: false});
                }
            },
            deep:true
        }
    },
    created(){
            this.$emit('can-continue', {value: this.valid});
    },
    mounted() {
            this.$emit('can-continue', {value: this.valid});

            this.id_tratta_andata=this.GenericGlobalData[0].id_tratta_andata;
            this.id_tratta_ritorno=this.GenericGlobalData[1].id_tratta_ritorno;
            
            if(this.form_andata.persona.eta.length>0 && this.form_andata.persona.eta.length-1==parseInt(this.form.numero_passeggeri) && ((this.id_tratta_ritorno!="" && this.form_ritorno.persona.eta.length>0 && this.form_ritorno.persona.eta.length-1== parseInt(this.form.numero_passeggeri)) || this.id_tratta_ritorno==""))
            {
                
                this.$emit('can-continue', {value: true});
            }
            if(this.form_ritorno.persona.eta.length>0 && this.form_ritorno.persona.eta.length-1==parseInt(this.form.numero_passeggeri) && ((this.id_tratta_andata!="" && this.form_andata.persona.eta.length>0 && this.form_andata.persona.eta.length-1== parseInt(this.form.numero_passeggeri)) || this.id_tratta_andata==""))
            {
                
                this.$emit('can-continue', {value: true});
            }

        },
    methods:{
        form_restituito_andata(form)
        {
            this.form_andata=form;
        },
        form_restituito_ritorno(form)
        {
            this.form_ritorno=form;
        }
    }
}
</script>

<style>
.panel-title{
    font-size: 19px;
    padding: 2%;
    text-align: center;
    color:#235e82;
}
.title{
    background:#ffc845 ;
    text-align: center;
    padding: 2%;
}
</style>
<style scoped>
.panel-primary{
    border:1px solid transparent;
    border-color: #337ab7;
    border-radius: 5px;
}

.viaggi{
    padding: 13px;
    margin-top:35px;
}

 @media (max-width: 767px)
    {
        .viaggio_andata{
            margin-top:5%;
        }
    }
</style>
