
import axios from 'axios'

//http://192.168.3.43:8088/traghetti/'

const ApiService=axios.create({
    baseURL:'https://globalferry.eu/',
    headers:{
        'Content-Type' : 'application/json; charset=UTF-8'
    }
});

export default{
    async getDataDisp(tipo,id_tratta,data_partenza)
    {
        if(tipo==1)
        {
            var porti=id_tratta.split('.');
        
            var id_porto_p=porti[0];
            var id_porto_a=porti[1];

            id_tratta=id_porto_a+"."+id_porto_p;
        }

        var params=({
            'tratta':id_tratta,
            'data':data_partenza
        });

        return await ApiService.post("admin/api/custom.php?data_disponibile",params)

    },
    async richiestaContatto(form_richiesta)
    {
        var params=({
            richiesta:form_richiesta
        });

        return await ApiService.post("admin/api/custom.php?richiestaContatto",params)

    },
    async richiestaPrenotazione(form,dettaglio,richiedente)
    {
        var params=({
            'info_form':form,
            'info_dettaglio':dettaglio,
            'info_richiedente':richiedente
        });

        return await ApiService.post("admin/api/custom.php?inserisciPrenotazione",params)

    },
    async getElencoTratte()
    {
        return await ApiService.get("admin/api/custom.php?tratte");
    },
    async getElencoTipiVeicoli()
    {
        return await ApiService.get("admin/api/custom.php?tipi_veicoli");
    },
    async getElencoSistemazioni(id_tratta)
    {
        return await ApiService.get("admin/api/custom.php?sistemazioni&id_tratta="+id_tratta);
    },
    async getElencoServizi(id_tratta)
    {
        return await ApiService.get("admin/api/custom.php?servizi&id_tratta="+id_tratta);
    },
    async getTratte(tipo,form)
    {
        var id_porto_p;
        var id_porto_a;
        
        var porti=form.id_tratta_andata.split('.');
            console.log(porti);

            id_porto_p=porti[0];
            id_porto_a=porti[1];
        var params;
        //andata
        if(tipo==1)
        {
            params=({
                id_porto_p:id_porto_p,
                id_porto_a:id_porto_a,
                data_p:form.data_partenza
            });

            console.log(params);
        }
        else{

            params=({
                id_porto_p:id_porto_a,
                id_porto_a:id_porto_p,
                data_p:form.data_ritorno
            });

            console.log(params);
        }

        return await ApiService.post("admin/api/custom.php?tratte_disp",params)
    }
}