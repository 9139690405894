
<template>
        <div class="container form" style="width: 50%;">
            <p v-if="errors.length">
                <b>Correggere i seguenti errori per continuare:</b>
                <ul>
                <li style="color:red;" v-for="error in errors" :key="error">{{ error }}</li>
                </ul>
            </p>
          <div class="row">
            <div class="col-md-12 col-md-pull-7">
                <div class="booking-form">
                  <form @submit="custom_submit"> 
                    <div class="row">
                        <div class="col-sm-6">
                          <div class="form-group">
                              <input class="form-check-input" value="1" v-model="form.andata_ritorno" type="radio" name="flexRadioDefault" id="flexRadioDefault1" checked>
                              <label class="form-check-label" for="flexCheckDefault">
                                  Andata e ritorno
                              </label>
                          </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group">
                                <input class="form-check-input" type="radio" value="0" v-model="form.andata_ritorno" name="flexRadioDefault" id="flexRadioDefault2">
                                <label class="form-check-label" for="flexCheckDefault">
                                    Solo andata
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <span class="form-label">Tratta</span>
                        <ElencoTratte v-model="form.id_tratta_andata" :data="tratte"></ElencoTratte>
                    </div>
                    <div class="row">
                        <div class="col-sm-6">
                            <div class="form-group">
                                <span class="form-label">Data partenza</span>
                                <input class="form-control" id="data_part" v-model="form.data_partenza" type="date" required>
                            </div>
                        </div>
                        <template v-if="form.andata_ritorno=='1'">
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <span class="form-label">Data ritorno</span>
                                    <input class="form-control" id="data_rit" v-model="form.data_ritorno" type="date" required>
                                </div>
                            </div>
                        </template>
                    </div>
                    <div class="row">
                        <div class="col-sm-4">
                            <div class="form-group">
                                <span class="form-label">Persone</span>
                                <select v-model="form.numero_passeggeri" class="form-control">
                                    <option>1</option>
                                    <option>2</option>
                                    <option>3</option>
                                    <option>4</option>
                                    <option>5</option>
                                    <option>6</option>
                                    <option>7</option>
                                    <option>8</option>
                                    <option>9</option>
                                </select>
                                <span class="select-arrow"></span>
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <div class="form-group">
                                <span class="form-label">Animali</span>
                                <select v-model="form.numero_animali" class="form-control">
                                    <option>1</option>
                                    <option>2</option>
                                    <option>3</option>
                                    <option>4</option>
                                    <option>5</option>
                                </select>
                                <span class="select-arrow"></span>
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <div class="form-group">
                                <span class="form-label">Veicolo</span>
                                <ElencoTipiVeicoli v-model="form.id_tipo_veicolo" :data="tipi_veicoli"></ElencoTipiVeicoli>
                            </div>
                        </div>
                    </div>
                    <div class="form-btn">
                        <VueButtonSpinner class="submit-btn" :is-loading="isLoading"  :disabled="isLoading" :status="status">Cerca disponibilità</VueButtonSpinner>
                    </div>
                  </form>
                </div>
              </div>
            </div>
        </div>
</template>

<script>

import ElencoTratte from '@/components/ElencoTratte.vue'
import ElencoTipiVeicoli from '@/components/ElencoTipiVeicoli.vue'
import ServizioApi from '@/servizi/ServizioApi.js'

import VueButtonSpinner from 'vue-button-spinner';

export default {
    name: 'CustomForm',
    components:{
        ElencoTratte,VueButtonSpinner,ElencoTipiVeicoli
    },
    data() {
        return {
            tratte:[],
            tipi_veicoli:[],
            errors:[],
             isLoading: false,
            status: '',
            trovate:'true',
            form:{
                andata_ritorno:'1',
                id_tratta_andata:'',
                id_tratta_ritorno:'',
                data_partenza:'',
                data_ritorno:'',
                numero_passeggeri:1,
                numero_animali:0,
                id_tipo_veicolo:''
            }
        }
    },
    mounted(){
 
        var dtToday = new Date();
        var month = dtToday.getMonth() + 1;
        var day = dtToday.getDate();
        var year = dtToday.getFullYear();
        if(month < 10)
            month = '0' + month.toString();
        if(day < 10)
            day = '0' + day.toString();
        
        var maxDate = year + '-' + month + '-' + day;

        document.getElementById('data_part').setAttribute('min',maxDate);
        document.getElementById('data_rit').setAttribute('min',maxDate);
    },
    async created()
    {
       await ServizioApi.getElencoTratte()
       .then(risposta=>(this.tratte=risposta.data.data))

       await ServizioApi.getElencoTipiVeicoli()
       .then(risposta=>(this.tipi_veicoli=risposta.data.data))

    },
    methods:{
        custom_submit(e)
        {
            this.isLoading = true

            if(this.errors.length>0)
            {
                this.errors.splice(0);
                this.isLoading = false
                //this.status = false 
            }

            if(!this.form.id_tratta_andata)
            {
                this.errors.push("Indicare una tratta di partenza!");
                this.isLoading = false
                //this.status = false 
            }

            if(!this.form.data_partenza)
            {
                this.errors.push("Indicare una data di partenza!");
                this.isLoading = false
                //this.status = false 
            }

            if(this.form.andata_ritorno=="1" && !this.form.data_ritorno)
            {
                this.errors.push("Indicare una data di ritorno!");
                this.isLoading = false
                //this.status = false 
            }

            if(!this.form.numero_passeggeri)
            {
                this.errors.push("Indicare un numero di passeggeri!");    
                this.isLoading = false
                //this.status = false 
            }

            //this.status = true 

            setTimeout(() => {
                this.$emit('form_submit',this.form);
                this.isLoading=false;
                }, 4000);            

            e.preventDefault();
        }
    }
}
</script>

<style scoped>
.submit{
    border: 1px solid transparent;
    border-radius: 15px;
    background: #0d6efd;
    text-align: center;
}
.btn{
    background: none !important;
    border:none !important;
    padding:10px;
}
@media (max-width: 767px)
{
.form{
    width: 100% !important;
}
}
</style>