<template>
    <div>
        <select v-model="selected" class="form-control">
            <option value="0" selected="selected"></option>
            <option v-for="tipo_veicolo in this.data" :value="tipo_veicolo.id_tipo_veicolo" :key="tipo_veicolo.id_tipo_veicolo">
                {{tipo_veicolo.Nome}}
            </option>
        </select>
        <span class="select-arrow"></span>
    </div>
</template>

<script>
export default {
    name:'ElencotipiVeicoli',
    props:{
        'data':Array
    },
    data() {
        return{

        }
    },
    computed:{
        selected:{
            get(){return this.value},
            set(v) {
                this.$emit('input', v);
            }
        }
    }
}
</script>