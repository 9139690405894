<template>
    <div>
        <div class="sistemazioni" style="">
            <template v-if="form_andata">
                    <button @click="copia_dati_form" class="form-control"><i style="margin-right:3px;" class="fas fa-copy"> </i>Copia dall'andata</button>
                </template>
            <div id="elenco_sis">
                <div class="section section1">
                    <span>Sistemazioni</span>
                </div>
                <div v-for="index in parseInt(this.form.numero_passeggeri)" :key="index">
                    <div class="panel-heading" style="text-align:center;" role="tab" id="heading-go-Accommodations">
                        <span>Persona </span> <span class="dot"> {{index}} </span>
                    </div>
                      <div class="form-group row">
                            <label for="inputPassword" class="col-sm-4 col-form-label">Sistemazione</label>
                            <div class="col-sm-8">
                            <select :style="inputStyles(form_sistemazioni.persona.id_sistemazione[index])" v-model.lazy="form_sistemazioni.persona.id_sistemazione[index]" class="form-control">
                                <option value="default">Seleziona sistemazione</option>
                                <option v-for="sistemazione in sistemazioni" :value="sistemazione.id_sistemazione"  :key="sistemazione.id_sistemazione">
                                    {{sistemazione.Nome}}
                                </option>
                            </select>
                            </div>
                       </div>
                       <div class="form-group row">
                            <label for="inputPassword" class="col-sm-4 col-form-label">Età</label>
                            <div class="col-sm-8">
                            <input :style="inputStyles(form_sistemazioni.persona.eta[index])" v-model.lazy="form_sistemazioni.persona.eta[index]" type="number" class="form-control"  placeholder="Età">
                            </div>
                       </div>
                       <div class="form-group row">
                            <label for="inputPassword" class="col-sm-4 col-form-label">Nazionalità</label>
                            <div class="col-sm-8">
                            <select :style="inputStyles(form_sistemazioni.persona.nazionalita[index])" class="form-control" name="nazionalita" v-model.lazy="form_sistemazioni.persona.nazionalita[index]">
                                <option value="Italia">Italia</option>
                            </select>
                            <!--<input v-model.lazy="form_sistemazioni.persona.nazionalita[index]" type="text" class="form-control" placeholder="Nazionalità">-->
                            </div>
                       </div>
                </div>
                <!--
                <template v-if="parseInt(this.form.numero_animali)>0">
                <div class="section section1">
                    <span>Animali domestici</span>
                </div>
                <div v-for="ind in parseInt(this.form.numero_animali)" :key="ind">
                    <div class="panel-heading" style="text-align:center;" role="tab" id="heading-go-Accommodations">
                        <span>Animale </span> <span class="dot"> {{ind}} </span>
                    </div>
                     <div class="form-group row">
                        <label for="inputPassword" class="col-sm-4 col-form-label">Tipologia</label>
                        <div class="col-sm-8">
                            <select v-model.lazy="form_sistemazioni.animale.tipologia[ind]" class="form-control">
                                <option value="default">Seleziona tipologia</option>
                                <option value="domestico">Domestico</option>
                            </select>
                        </div>
                     </div>
                </div>
                </template>
                -->
            </div>
        </div>
    </div>
</template>


<script>

import ServizioApi from '@/servizi/ServizioApi.js'

export default {
    name:'FormSistemazioni',
    props:['form','id_tratta','form_andata','titolo'],
    data() {
        return {
            sistemazioni:[],
            form_sistemazioni:{
                persona:{
                    id_sistemazione:[],
                    eta:[],
                    nazionalita:[]
                },
                animale:{
                    tipologia:[]
                }
            }
        }
    },
    watch:{
        form_sistemazioni:{ 
            handler(){
                this.$emit('onReturn',this.form_sistemazioni);
            },
            deep:true
        }
    },
    async created(){
        await ServizioApi.getElencoSistemazioni(this.id_tratta)
       .then(risposta=>(this.sistemazioni=risposta.data.data)) 
    },
    methods:{
        inputStyles(value) {
            var bc="";

            if(value=="" || value==0 || value==undefined)
            {
                bc="red";
            }
            
            return {
            "border-color": bc
            };
        },
        copia_dati_form()
        {
            try {

                this.form_sistemazioni.animale=JSON.parse(JSON.stringify(this.form_andata.animale));
            
                var eta=JSON.parse(JSON.stringify(this.form_andata.persona.eta));
                var nazionalita=JSON.parse(JSON.stringify(this.form_andata.persona.nazionalita));
                var id_sistemazione=JSON.parse(JSON.stringify(this.form_andata.persona.id_sistemazione));

                this.cambia_form(eta,nazionalita,id_sistemazione)
                
            } catch (error) {
                alert("Si è verificato un errore "+error);
            }
        },
        cambia_form(eta,nazionalita,id_sistemazione)
        {
                this.form_sistemazioni.persona.eta=eta;
                this.form_sistemazioni.persona.nazionalita=nazionalita;
                this.form_sistemazioni.persona.id_sistemazione=id_sistemazione;
        }
    }
}
</script>

<style lang="postcss" scoped>
.panel-heading{
    text-align: center;
    background-color: gainsboro;
    padding: 10px;
    border: 1px solid transparent;
    border-radius: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
}
.section{
    padding: 10px;
    background-color: #0d77d3;
    color: white;
}
.form-group{
    margin-bottom: 5px;
    padding: 1px;
    padding-left: 5px;
    padding-right: 5px;
}
.dot{
    height: 25px;
    width: 25px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    color:white;
}
</style>