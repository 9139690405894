<template>
    <div>
        <div class="container base_container">
            <div class="row">

                <div class="col-md-12 viaggi">
                    <template v-if="inviata">
                    <div>
                        <b-alert show :variant="esito">
                            <h4 class="alert-heading">{{richiesta.riscontro}}</h4>
                            <p>
                            {{richiesta.titolo}}
                            </p>
                            <hr>
                            <p class="mb-0">
                            {{richiesta.descrizione}}
                            </p>
                        </b-alert>
                    </div>
                    </template>

                    <div class="panel-primary">

                            <div class="title" style="background-color: #0d77d3;">
                                <span class="panel-title" style="color:white;">Compilazione dati richiedente</span>
                            </div>
                           
                        <form @submit="invia_richiesta_preventivo" class="form_richiedente"> 

                            <div class="row">
                               <div class="col-md-6">
                                    <label class="form-label" for="nome">Nome</label>
                                    <input :style="inputStyles(form_richiedente.nome)" type="text" class="form-control" v-model="form_richiedente.nome" id="nome" required>
                               </div>

                               <div class="col-md-6">
                                    <label class="form-label" for="nome">Cognome</label>
                                    <input :style="inputStyles(form_richiedente.cognome)" type="text" class="form-control" v-model="form_richiedente.cognome" required>
                               </div>
                               <div class="col-md-12">
                                    <label class="form-label" for="nome">Email</label>
                                    <input :style="inputStyles(form_richiedente.email)" type="email" class="form-control" v-model="form_richiedente.email" required>
                               </div>
                               <div class="col-md-4">
                                    <label class="form-label" for="nome">Telefono</label>
                                    <input :style="inputStyles(form_richiedente.telefono)" type="text" class="form-control" minlength="9" v-model="form_richiedente.telefono" required>
                               </div>
                               <div class="col-md-12">
                                    <label class="form-label" for="nome">Note aggiuntive</label>
                                      <textarea class="form-control" placeholder=" " v-model="form_richiedente.note_agg" id="floatingTextarea2" style="height: 100px"></textarea>
                                   
                               </div>
                               <div class="col-12 submit">    
                                   <br> 
                                    <VueButtonSpinner style="border-color:#30c1af !important;float:right;" :is-loading="isLoading"  :disabled="isLoading" :status="status"> <span style="">Invia richiesta</span> </VueButtonSpinner>
                                </div>
                            </div>
                        </form>
                    </div>

                </div>
            </div>

        </div>
    </div>
</template>

<script>
import VueButtonSpinner from 'vue-button-spinner';
import ServizioApi from '@/servizi/ServizioApi.js'

export default {
    name:'step1',
    components:{VueButtonSpinner},
    props: ['clickedNext', 'currentStep','GenericGlobalData','form'],
    data() {
        return {
            valid:true,
            value:0,
            inviata:false,
            successo:false,
            isLoading:false,
            status:'',
            form_richiedente:{
                nome:"",
                cognome:"",
                email:"",
                note_agg:"",
                telefono:""
            },
            richiesta:{
                riscontro:"",
                titolo:"",
                descrizione:""
            }
        }
    },
    methods:{
        inputStyles(value) {
            var bc="";

            if(value=="" || value==0 || value==undefined)
            {
                bc="red";
            }
            
            return {
            "border-color": bc
            };
        },
        async invia_richiesta_preventivo(e)
        {
            e.preventDefault();
            var risp;
            
            //TODO: FARE TUTTE LE INSERT
            await ServizioApi.richiestaPrenotazione(this.form,this.GenericGlobalData,this.form_richiedente)
            .then(risposta=>(risp=risposta.data))

            this.successo=(risp.esito==="true");
            
            if(this.successo)
            {
                this.esito="success"
                this.richiesta.riscontro="La  richiesta è stata inviata correttamente!"
                this.richiesta.descrizione=""
                this.inviata=true;

                setTimeout(() => {
                    location.href="/";
                }, 3000);  
                
            }
            else{
                this.esito="danger"
                this.richiesta.riscontro="Ops.. Si è verificato un errore!"
                this.richiesta.descrizione="Riprova!"
                this.inviata=true;
            }
            
        }
    }
}
</script>

<style>
.panel-title{
    font-size: 19px;
    padding: 2%;
    text-align: center;
    color: black;
}
.title{

    background-color: #f1f6f5;
    text-align: center;
    padding: 2%;
}
</style>
<style scoped>
.panel-primary{
    border:1px solid transparent;
    border-color: #337ab7;
    border-radius: 5px;
}
.form_richiedente{
    padding:10px;
}
.viaggi{
    padding: 13px;
    margin-top:35px;
}

 @media (max-width: 767px)
    {
        .viaggio_andata{
            margin-top:5%;
        }
    }
</style>
