<template>
    <div>
        <div class="servizi" style="">
            <div id="elenco_sis">
                <div class="section section1">
                    <span>Servizi Aggiuntivi</span>
                </div>
                <div class="form-group row">
                    <!--<label for="inputPassword" class="col-sm-4 col-form-label">Servizio</label>-->
                    <div class="col-sm-12">  
                        <!--
                        <select v-model.lazy="form_servizi.id_servizio[index]" class="form-control">
                            <option value="default">Seleziona servizio</option>
                            <option value="taxi">Taxi</option>
                        </select>
                        -->
                        <template v-if="servizi_disponibili==0">
                            <span style="text-align:center;">Nessun servizio aggiuntivo disponibile.</span>
                        </template>

                        <div class="table-responsive">
                        <table class="table">
                            <thead>
                                <tr>
                                <th scope="col"></th>
                                <th scope="col"></th>
                                <th scope="col"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for=" (servizio,index) in servizi" :key="servizio.id_servizio">
                                <td scope="row">{{servizio.Nome_servizio}}</td>
                                <td><input :type="tipo(servizio.Tipo_servizio)" class="form-group" v-model="form_servizi.valore[index]"></td>
                                <td><b-button class="btn_info" v-b-popover.hover.left="servizio.Descrizione" title="Informazioni"><i class="fa fa-info-circle" style="color:white;" aria-hidden="true"></i></b-button></td>
                                </tr>
                            </tbody>
                        </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ServizioApi from '@/servizi/ServizioApi.js'


export default {
    name:'FormServizi',
    props:['form','id_tratta','titolo'],
    data() {
        return {
            servizi:[],
            count:1,
            servizi_disponibili:0,
            form_servizi:{
                id_servizio:[],
                valore:[]
            }
        }
    },
    watch:{
        form_servizi:{ 
            handler(){
                this.$emit('onReturn',this.form_servizi);
            },
            deep:true
        }
    },
    async created(){
        await ServizioApi.getElencoServizi(this.id_tratta)
       .then(risposta=>(this.servizi=risposta.data.data))

       var count=0;
       this.servizi_disponibili=0;

       if(Array.isArray(this.servizi))
       {
            this.servizi_disponibili=this.servizi.length;    
            this.servizi.forEach(e => {
                    
                    this.form_servizi.id_servizio[count] = e.id_servizio
                    count++;

            });

       }
       
    },
    computed:{
        
    },
    methods:{
        tipo(tipo)
        {
            if(tipo=="N")
            {
                return "number";
            }
            if(tipo=="B")
            {
                return "checkbox";
            }
        },
        alert(desc)
        {
            alert(desc);
        },
        decrement()
        {
            if(this.count>1)
            {
                try{
                    this.form_servizi.id_servizio.splice(this.count,1);
                    this.count=this.count-1;
                }   
                catch
                {
                    alert("Impossibilire eliminare l'elemento!");
                }
            }       
        }
        /*
        copia_dati_form()
        {
            try {

                this.form_sistemazioni.animale=JSON.parse(JSON.stringify(this.form_andata.animale));
            
                var eta=JSON.parse(JSON.stringify(this.form_andata.persona.eta));
                var nazionalita=JSON.parse(JSON.stringify(this.form_andata.persona.nazionalita));
                var id_sistemazione=JSON.parse(JSON.stringify(this.form_andata.persona.id_sistemazione));

                this.cambia_form(eta,nazionalita,id_sistemazione)
                
            } catch (error) {
                alert("Si è verificato un errore "+error);
            }
        },
        cambia_form(eta,nazionalita,id_sistemazione)
        {
                this.form_sistemazioni.persona.eta=eta;
                this.form_sistemazioni.persona.nazionalita=nazionalita;
                this.form_sistemazioni.persona.id_sistemazione=id_sistemazione;
        }
        */
    }
}
</script>

<style lang="postcss" scoped>
.panel-heading{
    text-align: center;
    background-color: gainsboro;
    padding: 10px;
    border: 1px solid transparent;
    border-radius: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
}
.section{
    padding: 10px;
    background-color: #3383c8;
    color: white;
}
.btn_info{
    border:1px solid transparent;
    border-radius: 50%;
    background-color: #3383c8;
}
.form-group{
    margin-bottom: 5px;
    padding: 1px;
    padding-left: 5px;
    padding-right: 5px;
}

</style>