<template>
 <div>
    <div class="container">
    <div class="row">
        <div class="btn-group btn-group-justified btn-breadcrumb hidden-xs">
            <a href="/" class="bottoni btn btn-primary"><i class="fa fa-home" aria-hidden="true"></i></a>
            <a href="#" class="bottoni btn btn-warning">Itinerario</a>
            <a href="#" class="bottoni btn btn-default">Sistemazioni</a>
            <a href="#" class="bottoni btn btn-default">Preventivo</a>
            <a href="#" class="bottoni btn btn-default">Passeggeri</a>
            <a href="#" class="bottoni btn btn-default">Pagamento</a>
            <a href="#" class="bottoni btn btn-default">Riepilogo</a>
        </div>
    </div>
    </div>
    <div class="container">
        <div class="row">
       
        <template v-if="andata.length">
          <ElencoRisultati v-on:return="get_risultato_andata" :data="andata" :titolo="'Seleziona tratta andata'"/>
        </template>

        <template v-if="ritorno.length">
          <ElencoRisultati  style="margin-bottom:10%;" v-on:return="get_risultato_ritorno" :data="ritorno" :titolo="'Seleziona tratta ritorno'"/>
        </template>
      </div>
    </div>
 </div>
</template>

<script>
import ElencoRisultati from '@/components/ElencoRisultati.vue'

export default {
    components:{ElencoRisultati},
    data() {
        return {
            andata:[],
            ritorno:[]
        }
    },
    mounted(){
        console.log(this.$route.params.userid);
        this.andata=this.$route.params.andata;
        this.ritorno=this.$route.params.ritorno;
    },
    methods:{
        get_risultato_andata(result)
        {
            console.log(result);
        },
        get_risultato_ritorno(result)
        {
            console.log(result);
        }
        
    }
}
</script>

<style scoped>
@media (min-width: 1200px)
{

.container {
    width: 1170px;
}
}
.btn-group-vertical>.btn, .btn-group>.btn {
    position: relative;
    float: left;
}
.btn-group{
    padding-top:5%;
    padding-right: 5%;
    padding-left: 5%;
}
.bottoni{
    border:1px solid !important;
    border-radius: 5px;
}
</style>