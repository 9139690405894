<template>
    <div class="elenco_tratte_div">
        <template v-if="risultati_andata">
          <ElencoRisultati :key="$route.fullPath" v-on:return="get_risultato_andata" :data="risultati_andata" :titolo="'Seleziona tratta andata'"/>
        </template>
         <template v-if="risultati_ritorno.length">
            <ElencoRisultati :key="$route.fullPath" v-on:return="get_risultato_ritorno" :data="risultati_ritorno" :titolo="'Seleziona tratta ritorno'" />
        </template>
    </div>
</template>

<script>
import ElencoRisultati from '@/components/ElencoRisultati.vue'

export default {
    name:'step1',
    props: ['clickedNext', 'currentStep','form','risultati_andata','risultati_ritorno'],
    components:{ElencoRisultati},
    data() {
        return {
            valid:false,
            id_tratta_andata:'',
            id_tratta_ritorno:''
        }
    },
    watch:{
        id_tratta_andata:{
            handler: function () {
                if(this.id_tratta_andata!="")
                {
                    this.$emit('onReturn',{'tipo':'tratta_andata','valore':this.id_tratta_andata});
                }

                if(this.id_tratta_andata!="" && (!this.risultati_ritorno.length>0 && this.id_tratta_ritorno=="") || (this.risultati_ritorno.length>0 && this.id_tratta_ritorno!=""))
                {
                    this.$emit('can-continue', {value: true});  
                    this.valid=true;
                }
                else{
                    this.valid=false;
                }
            }
        },
        id_tratta_ritorno:{
           handler: function () {
                if(this.id_tratta_ritorno!="")
                {
                    this.$emit('onReturn',{'tipo':'tratta_ritorno','valore':this.id_tratta_ritorno});
                }

                if(this.id_tratta_ritorno!="" && (!this.risultati_andata.length>0 && this.id_tratta_andata=="") || (this.risultati_andata.length>0 && this.id_tratta_andata!=""))
                {
                    this.$emit('can-continue', {value: true});  
                    this.valid=true;
                }
                else{
                    this.valid=false;
                }
            }
        }
    },
    created(){
            this.$emit('can-continue', {value: false});
            if(this.id_tratta_andata!="")
            {
                this.$emit('can-continue', {value: true});
            } 
    },
    mounted() {
            this.$emit('can-continue', {value: false});
            if(this.id_tratta_andata!="")
            {
                this.$emit('can-continue', {value: true});
            } 
        },
    methods:{
        get_risultato_andata(result)
        {
            this.id_tratta_andata=result;
        },
        get_risultato_ritorno(result)
        {
            this.id_tratta_ritorno=result;
        }
    }
}
</script>

<style scoped>

    @media (max-width: 767px)
    {
        .elenco_tratte_div{
            margin-top:5%;
        }
    }
</style>